<template>
  <div class="page-title">
    <div class="page-title--left" v-if="!isMobile">
      <h1> {{ discount?.discountName || '' }} </h1>
      <q-breadcrumbs>
        <q-breadcrumbs-el
          label="Home"
          :to="{
            name: 'Home',
          }"
        />
        <q-breadcrumbs-el :label="discount?.discountName || ''" />
      </q-breadcrumbs>
    </div>
    <div class="page-title--right q-ml-auto" v-if="!isMobile">
      <div class="sorting flex items-center">
        <span>Sort :</span>
        <q-select
          dense
          borderless
          v-model="sortBy"
          :options="sortByOptions"
          @input="sortByModified"
          transition-show="jump-up"
          transition-hide="jump-up"
          emit-value
          map-options
        />
      </div>
      <ProductLayoutIcons
        v-if="!isMobile"
        @changeView="(val) => this.$emit('changeView', val)"
        :isListView="discountFilters.isListView"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductLayoutIcons from 'components/common/ProductLayoutIcons'

export default {
  name: 'DiscountHeader',
  components: {
    ProductLayoutIcons,
  },
  props: {
    discount: {
      type: Object,
      default: () => {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sortVal: {
      type: String,
      default: 'Featured',
    },
  },
  data() {
    return {
      sortBy: this.sortVal,
    }
  },
  computed: {
    ...mapGetters('product', ['sortByOptions']),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    discountFilters() {
      return this.getFilterByPage('discount')
    },
  },
  methods: {
    sortByModified(value) {
      this.$emit('sorby-modified', this.sortBy)
    },
  },
}
</script>
