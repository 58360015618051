<template>
  <q-page class="productListing">
    <template v-if="!loadingCurrentDiscount && !loading && currentDiscount">
      <DiscountHeader
        v-if="currentDiscount"
        :discount="currentDiscount"
        :sortVal="discountFilters.sortBy"
        @sorby-modified="sortByModified"
        @changeView="changeView"
      />
      <Listing
        v-if="currentDiscount"
        :discount="currentDiscount"
        :discountId="currentDiscount?.discountID || null"
        productsBy="DT"
        :sortBy="discountFilters.sortBy"
        :pageSize="discountFilters.pageSize"
        :isListView="!isMobile ? discountFilters.isListView : false"
        showDiscounts
        @sorby-modified="sortByModified"
      />
      <EmptyComponent
        v-if="Object.keys(discounts)?.length == 0"
        :image="`images/empty-product.png`"
        btnText="Go To Home"
        to="/"
      >
        <p>No Discounts available for this store.</p>
      </EmptyComponent>
    </template>
    <ProductListingSkeleton
      v-else-if="loading || (loadingCurrentDiscount && !currentDiscount)"
      :showHeaderLoader="true"
      :showFilterBarLoader="
        loading || (loadingCurrentDiscount && !currentDiscount)
      "
      :showListingLoader="true"
      :isListView="!isMobile ? discountFilters.isListView : false"
    />
    <EmptyComponent
      image="images/empty-product.png"
      btnText="Continue Shopping"
      to="/"
      v-else-if="!loading && !loadingCurrentDiscount && !currentDiscount"
    >
      <p>
        Currently, this discount is not available for the selected location.
        Please select another location.
      </p>
    </EmptyComponent>
    <!-- </div> -->
    <TriggerListDialog v-if="currentDiscount" />
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import DiscountHeader from 'components/discount/DiscountHeader'
import Listing from 'components/product/Listing'

export default {
  name: 'DiscountDetail',
  components: {
    DiscountHeader,
    Listing,
    TriggerListDialog: () => import('components/discount/TriggerListDialog'),
    ProductListingSkeleton: () => import('components/ProductListingSkeleton'),
  },
  props: {
    seoName: {
      type: String,
      default: '',
    },
  },
  async preFetch({ store, currentRoute, redirect }) {
    let { seoName } = currentRoute.params,
      promise

    /* store.dispatch('discounts/getDiscounts').then(() => {
      let discounts = store.getters['discounts/discounts']

      if (!seoName && Object.keys(discounts)[0])
        redirect({
          name: 'DiscountDetail',
          params: { seoName: Object.keys(discounts)[0] },
        })
    }) */

    if (seoName) {
      promise = store.dispatch('discounts/getDiscountDetail', seoName)
      // .then((data) => {
      //   if (!data) store.commit('discounts/SET_LOADING', false)
      //   // redirect({
      //   //   name: 'Home',
      //   // })
      // })
    }

    if (process.env.SERVER) return promise
  },
  meta() {
    return {
      title: this.title,
      ...this.commonMeta,
    }
  },
  computed: {
    ...mapGetters('discounts', [
      'discounts',
      'currentDiscount',
      'loadingCurrentDiscount',
      'loading',
    ]),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    discountFilters() {
      return {
        ...this.getFilterByPage('discount'),
        pageSize: 20,
      }
    },
    title() {
      return this.currentDiscount?.discountName || ''
    },
  },
  data() {
    return {}
  },
  methods: {
    changeView(isListView) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'discount',
        filter: {
          ...this.discountFilters,
          isListView,
        },
      })
    },
    sortByModified(sortBy) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'discount',
        filter: {
          ...this.discountFilters,
          sortBy,
        },
      })
    },
  },
  destroyed() {
    this.$store.commit('product/RESET_PAGINATION')
  },
  mounted() {
    // prefetch code
    let { seoName } = this.$route.params

    this.$store.dispatch('discounts/getDiscounts').then(() => {
      let discounts = this.$store.getters['discounts/discounts']

      if (!seoName && Object.keys(discounts)[0])
        this.$router.push({
          name: 'DiscountDetail',
          params: { seoName: Object.keys(discounts)[0] },
        })
    })
    // prefetch code
  },
}
</script>

<style lang="scss"></style>
